import * as Sentry from '@sentry/sveltekit'

import { browser, building } from '$app/environment'

import { sentry_options } from './shared'

if (!building && browser) {
	Sentry.init({
		...sentry_options,

		// client stuff
		replaysSessionSampleRate: 0.05,
		replaysOnErrorSampleRate: 1.0,
		integrations: [Sentry.replayIntegration()],
	})
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleErrorSentry = Sentry.handleErrorWithSentry
