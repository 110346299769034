import type { Options } from '@sentry/types'

import { PUBLIC_SENTRY_DSN } from '$env/static/public'
import { dev } from '$app/environment'

export const sentry_options: Options = {
	dsn: PUBLIC_SENTRY_DSN,
	environment: dev ? 'development' : 'production',
	tracesSampleRate: 0.05,

	beforeSend(event, hint) {
		// @ts-ignore
		if (hint.originalException?.status === 409) {
			return null
		}

		return event
	},

	// debug: true,
}
